import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Player } from "@lottiefiles/react-lottie-player"

const pageTitle = "تماس با شرکت مشارکت نیرو"

export default function ContactUsPage() {
  return (
    <Layout>
      <Seo
        title={pageTitle}
        description="نحوه تماس با دفاتر شرکت مشارکت نیرو آریا کیش"
      />
      <div className="container px-0 sm:px-2">
        <section
          id="contact-us-hero"
          className="grid grid-cols-2 px-5 sm:grid-cols-1 items-center gap-10 sm:px-1 sm:py-10"
        >
          <div
            id="hero-animation"
            className="border rounded-md px-2 m-5 sm:m-2 shadow-lg shadow-gray-300"
          >
            <Player
              autoplay
              loop
              src="https://assets8.lottiefiles.com/packages/lf20_qxmkn9ou.json"
            ></Player>
          </div>
          <div className="flex flex-col items-center space-y-6">
            <h1 className="text-6xl sm:text-4xl font-semibold text-primary">
              {pageTitle}
            </h1>
            <h2 className="text-2xl sm:text-xl font-semibold text-primary">
              از طرق ذیل آماده پاسخگویی شما هستیم.
            </h2>
          </div>
        </section>

        <section
          id="contact-us"
          className="grid grid-cols-2 sm:grid-cols-1 gap-10 p-10 text-2xl leading-10 bg-primary text-white"
        >
          <div id="kish-office" className="">
            <h3 className="text-secondary">دفتر مرکزی شرکت مشارکت نیرو</h3>
            <p>جزیره کیش</p>
            <p>بلوار مروارید، ساختمان شارستان، طبقه اول واحد 90</p>
            <p>تلفن : 07644458035</p>
            <p>نمابر : 07644458795</p>
            <p>
              پست الکترونیکی :&nbsp;
              <a href="mailto:info@mosharekatniroo.com">
                info@mosharekatniroo.com
              </a>
            </p>
            <a
              href="https://goo.gl/maps/YB3GbUYz7Nrngss67"
              className="px-10 py-3  bg-secondary text-xl text-white max-w-max rounded shadow-lg shadow-black hover:bg-primary"
            >
              ملاحظه بر روی نقشه
            </a>
          </div>
          <div id="tehran-office" className="">
            <h3 className="text-secondary">دفتر فروش شرکت مشارکت نیرو</h3>
            <p>تهران</p>
            <p>خیابان فرشته، خیابان نیلوفر،ساختمان رویال آدرس، واحد 405</p>
            <p>تلفن : 26204201-021</p>
            <p>تلفکس : 26204301-021</p>
            <p>
              پست الکترونیکی :&nbsp;
              <a href="mailto:info@mosharekatniroo.com">
                info@mosharekatniroo.com
              </a>
            </p>
            <a
              href="https://goo.gl/maps/W4zaNdJfAtTjb1qE6"
              className="px-10 py-3 bg-secondary text-xl text-white max-w-max rounded shadow-lg shadow-black hover:bg-primary"
            >
              ملاحظه بر روی نقشه
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}
